:root {
  /* Grayscale Adjustments */
  --dl-color-gray-500: #36454F; /* Charcoal Grey for medium intensity */
  --dl-color-gray-700: #BCC6CC; /* Silver or Light Grey for lighter elements */
  --dl-color-gray-900: #D9D9D9; /* Adjusted light grey, close to the original for background contrasts */

  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  /* Danger Color Adjustments - Using Crimson Red */
  --dl-color-danger-300: #B71C1C; /* Darker shade of Crimson Red for less intense danger elements */
  --dl-color-danger-500: #D32F2F; /* Crimson Red as danger base color */
  --dl-color-danger-700: #E57373; /* Lighter Crimson Red for danger highlights */

  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  /* Primary Color Adjustments - Using Dark Blue Shades */
  --dl-color-primary-100: #002244; /* Darker shade of Dark Blue for less intense primary elements */
  --dl-color-primary-300: #003366; /* Dark Blue as primary base color */
  --dl-color-primary-500: #004080; /* Slightly brighter Dark Blue for hover states or active elements */
  --dl-color-primary-700: #00509E; /* Even brighter Dark Blue for selected states */

  /* Success Color Adjustments - Using Vibrant Green */
  --dl-color-success-300: #3E8E41; /* Darker shade of Vibrant Green for less intense success elements */
  --dl-color-success-500: #4CAF50; /* Vibrant Green as success base color */
  --dl-color-success-700: #5BC35F; /* Lighter Vibrant Green for success highlights */

  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-color-custom-accent1: #0060B9;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-custom-primary1: #0060B9;
  --dl-color-custom-primary2: #003D75;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-color-custom-secondary1: #E5F0FF;
  --dl-color-custom-neutral-dark: #141A22;
  --dl-color-custom-neutral-light: #FFFFFF;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.buttonFilled {
  color: var(--dl-color-custom-neutral-light);
  transition: 0.3s;
  font-family: Helvetica Neue;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-custom-primary1);
}
.buttonFilled:hover {
  background-color: var(--dl-color-custom-primary2);
}
.buttonFilledSecondary {
  color: var(--dl-color-custom-neutral-light);
  transition: 0.3s;
  font-family: Helvetica Neue;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-custom-primary1);
}
.buttonFilledSecondary:hover {
  background-color: var(--dl-color-custom-primary2);
}
.buttonOutline {
  color: var(--dl-color-custom-neutral-dark);
  border: 1px solid;
  font-family: Helvetica Neue;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-custom-neutral-dark);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 4px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-custom-secondary1);
}
.buttonOutline:hover {
  background-color: var(--dl-color-custom-primary2);
}
.buttonFlat {
  color: var(--dl-color-custom-neutral-dark);
  font-family: Helvetica Neue;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.buttonFlat:hover {
  color: var(--dl-color-custom-primary2);
}
.featuresContainer {
  flex: 0 0 auto;
  color: var(--dl-color-custom-neutral-dark);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-custom-secondary1);
}
.featuresCard {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.65);
}
.featuresIcon {
  fill: var(--dl-color-custom-neutral-dark);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.freePricingCard {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  min-height: 450px;
  align-items: flex-start;
  border-radius: 0px 60px 0px 0px;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.basicPricingCard {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  min-height: 450px;
  align-items: flex-start;
  border-radius: 0px 60px 0px 0px;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.proPricingCard {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  min-height: 450px;
  align-items: flex-start;
  border-radius: 0px 60px 0px 0px;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.faqContainer {
  flex: 0 0 auto;
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-custom-accent1);
}
.navbarContainer {
  width: 100%;
  display: flex;
  position: sticky;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-custom-neutral-light);
}
.mobileMenu {
  background-color: var(--dl-color-custom-neutral-light);
}
.heroContainer {
  width: 100%;
  display: flex;
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: var(--dl-color-custom-neutral-light);
}
.pricingContainer {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-custom-neutral-light);
}
.bannerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
  background-color: var(--dl-color-custom-neutral-light);
}
.footerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-custom-neutral-light);
}
.socialIcons {
  fill: var(--dl-color-custom-neutral-dark);
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.logo {
  font-size: 2em;
  font-family: Helvetica Neue;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
}
.heading1 {
  font-size: 48px;
  text-align: center;
  font-family: Helvetica Neue;
  font-weight: 700;
  line-height: 150%;
}
.heading2 {
  font-size: 35px;
  font-family: Helvetica Neue;
  font-weight: 600;
  line-height: 150%;
}
.heading3 {
  font-size: 20px;
  text-align: center;
  font-family: Helvetica Neue;
  font-weight: 600;
  line-height: 150%;
}
.bodyLarge {
  font-size: 18px;
  font-family: Helvetica Neue;
  font-weight: 400;
  line-height: 150%;
}
.bodySmall {
  font-size: 16px;
  font-family: Helvetica Neue;
  font-weight: 400;
  line-height: 150%;
}
.overline {
  font-size: 12px;
  font-family: Helvetica Neue;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
}
